import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; 

import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';    

export default function Experience() {
      return (
      <div className="App-exp">
        <h1>Work experience</h1>
        <VerticalTimeline>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Jun 2022 – Present"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">IT Project Manager</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: Peoples Group</h6>
            <p>
              Project Management, Team Leading
            </p>
            <p>
              IT project management, Project Charter, Project planning, Budgeting | Infrastructure and security
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Feb 2021 – Apr 2022"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Assistant Manager</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: PCCW (OTT | viu.com)</h6>
            <p>
              Development, Project Management, Team Leading
            </p>
            <p>
              Payment Channel intergration | Backend Integration Projects with India product | Internal system refactoring
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Feb 2019 – Feb 2021"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Senior Project Officer</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: PCCW (OTT | viu.com)</h6>
            <p>
              Development, Project Management
            </p>
            <p>
              Payment integration with different third parties across the regions: Thailand, Singapore, philippines, Hong Kong
            </p>
          </VerticalTimelineElement> 

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Mar 2017 – Feb 2019"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Web Developer</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: PCCW (Media | now.com)</h6>
            <p>
              API development (PHP)
            </p>
            <p>
              Mobile Apps (IOS, Objective C) 
            </p>
            <p>
              <a className="linkColor" href="https://finance.now.com">https://finance.now.com</a> 
            </p>
            <p>
              <a className="linkColor" href="https://news.now.com">https://news.now.com</a> 
            </p>
          </VerticalTimelineElement> 

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Mar 2016 – Feb 2017"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Assistant technical officer</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: Hong Kong Polytechnic University</h6>
            <p>
              Server and infrastructure management.
            </p>
            <p>
              Technical Support 
            </p>
            <p>
              System design and implementation
            </p>
          </VerticalTimelineElement> 

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Aug 2014 – Mar 2016"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Senior Web developer</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: AsOne Interactive (Social media)</h6>
            <p>
              Facebook application development
            </p>
            <p>
              Instagram API and WeChat application integration
            </p>
            <p>
              Digital marketing projects working with internal designer
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(31 79 118)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Nov 2013 – Aug 2014"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Web developer</h3>
            <h6 className="vertical-timeline-element-subtitle">Company Name: Splash Interactive </h6>
            <p>
              Collaborate with marketing team to develop various online and social media campaigns
            </p>
            <p>
              Website development
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              icon={<StarIcon />}
            />
        </VerticalTimeline>
        {/*<div className="gallery">
          <PhotoAlbum onClick={ClickHandler} layout="masonry" photos={photos} />
        </div>*/}
      </div>
      );
}