import * as React from 'react';
import './App.css';
import Summary from './Summary';
import { StyledEngineProvider } from '@mui/material/styles';
import ExperiencePage from './Experience';
import EducationPage from './Education';
import SkillPage from './Skills';
import LangPage from './Language';
import ProjectPage from './Projects';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import SchoolIcon from '@mui/icons-material/School';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import LanguageIcon from '@mui/icons-material/Language';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function App() {

  const [page, setPage] = React.useState(0);
  const [isClick, setisClick] = React.useState(0);
  const aboutmeRef = React.useRef(null);
  const expRef = React.useRef(null);
  const educRef = React.useRef(null);
  const skillRef = React.useRef(null);
  const projectRef = React.useRef(null);
  const langRef = React.useRef(null);
  const prevScrollY = React.useRef(0);
  const [goingUp, setGoingUp] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  console.log("isClick",process.env.REACT_APP_NOT_SECRET_CODE);

  function handleClick(value){
    var scrollTimeout;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function() {
      setPage(value);
    }, 700);
  };

  const pageMapping = [aboutmeRef,expRef,educRef,skillRef,projectRef,langRef];

  React.useEffect(() => {
    //console.log("useEffect");
    //pageMapping[page].current.scrollIntoView({ behavior: 'smooth'});

    //console.log(pageMapping);

    const handleScroll = (e) => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }
      //console.log(e);
      prevScrollY.current = currentScrollY;

      //if(isClick){
      //}else{
        if(currentScrollY>aboutmeRef.current.offsetTop&&currentScrollY<expRef.current.offsetTop){
          setPage(0);
        }else if(currentScrollY>expRef.current.offsetTop&&currentScrollY<educRef.current.offsetTop){
          setPage(1);
        }else if(currentScrollY>educRef.current.offsetTop&&currentScrollY<skillRef.current.offsetTop){
          setPage(2);
        }else if(currentScrollY>skillRef.current.offsetTop&&currentScrollY<projectRef.current.offsetTop){
          setPage(3);
        }else if(currentScrollY>projectRef.current.offsetTop&&currentScrollY<langRef.current.offsetTop){
          setPage(4);
        }else if(currentScrollY>langRef.current.offsetTop){
          setPage(5);
        }
      //}
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    /*var scrollTimeout;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function() {
        setisClick(0);
        console.log("scroll end");
    }, 2000);*/

    //return () => window.removeEventListener("scroll", handleScroll);
    //window.removeEventListener('scroll', handleScroll);
    return function cleanupListener() {
      window.removeEventListener('scroll', handleScroll);
    }

  }, [page,goingUp]);

  return (
    <div className="App">
      <div ref={aboutmeRef} className="App-header" >
          <div className="profilePic">
            <img alt="profilepic" className="pic" src="/images/avataaars.png"/>
          </div>
          <h3>I am Cyrus Lee</h3>
          <Summary/>
          <div className="desc">
                <p><b>- 6 Years Development Experience:</b> Hands-on experience in both frontend and backend application development. <br/>(React, TypeScript, Nodejs, PHP, Java, C#, Objective C)</p>
                <p><b>- 5 Years Project Management Experience:</b> Successfully managed projects using Jira and confluence, integrating 20+ partner systems into the product’s payment system, handling thousands of daily transactions.</p>
                <p><b>- International Project Leadership:</b> Led and oversaw projects across Thailand, Singapore, the Philippines, Hong Kong, and China.</p>
                <p><b>- Cross-Functional Collaboration:</b> Aligned business and technical requirements with partners, both externally and internally, ensuring seamless project execution.</p>
                <p><b>- Technical and Leadership Skills:</b> Combined technical expertise with leadership, hiring and leading a team of 5 developers in China.</p>
                <p><b>- Proven Track Record:</b> Demonstrated ability to deliver complex projects on time and within budget, improving system efficiency and reliability.</p>
          </div>
          <Stack direction="row" spacing={3}>
              <Button variant="contained" color="info" onClick={()=> window.open("mailto:cyruslee9266@gmail.com", "_blank")}>
                Hire Me
              </Button>
              <Button variant="contained" color="info" onClick={()=> window.open("https://www.linkedin.com/in/cyrus-lee-1a696a102/", "_blank")}>
                linkedin
              </Button>
          </Stack>
      </div>
      <div ref={expRef}>
        <ExperiencePage />
      </div>
      
      <div ref={educRef}>
        <EducationPage />
      </div>

      <div ref={skillRef}>
        <SkillPage />
      </div>

      <div ref={projectRef}>
        <ProjectPage />
      </div>      

      <div ref={langRef}>
        <LangPage />
      </div>

      <StyledEngineProvider injectFirst>
        <Box>
          <BottomNavigation className="BottomNavigation"
            showLabels
            value={page}
            onChange={(event, newValue) => {
              //console.log("onchange",newValue);
              if(event.type=="click"){
                //setisClick(1);
                pageMapping[newValue].current.scrollIntoView({ behavior: 'smooth'});
                setTimeout(() => {
                  //setPage(newValue);
                },500);
              }
            }}
          >
            <BottomNavigationAction  onClick={() => handleClick(0)} label="About Me" icon={<HomeIcon />} />
            <BottomNavigationAction  onClick={() => handleClick(1)} label="Experience" icon={<WorkHistoryIcon />} />
            <BottomNavigationAction  onClick={() => handleClick(2)} label="Education" icon={<SchoolIcon />} />
            <BottomNavigationAction  onClick={() => handleClick(3)} label="Skills" icon={<SettingsAccessibilityIcon />} />
            <BottomNavigationAction  onClick={() => handleClick(4)} label="Projects" icon={<ArtTrackIcon />} />
            <BottomNavigationAction  onClick={() => handleClick(5)} label="Languages" icon={<LanguageIcon />} />
          </BottomNavigation>
        </Box>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
