import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';  
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';  

export default function Lang() {
      return (
      <div className="App-lang">
        <h1>Language</h1>
          <Box
            sx={{
              '& > legend': { mt: 2 },
            }}
          >

            <Typography component="legend">English</Typography>
            <Rating name="customized-10" defaultValue={6} max={10} />

            <Typography component="legend">Mandarin</Typography>
            <Rating name="customized-10" defaultValue={8} max={10} />

            <Typography component="legend">Cantonese</Typography>
            <Rating name="customized-10" defaultValue={8} max={10} />

          </Box>

          <h1>Hobby</h1>
          <p>Gym,Netflix,Music</p>
      </div>
      );
}