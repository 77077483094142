import * as React from 'react';
import Box from '@mui/material/Box';   
import PropTypes from 'prop-types';

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        wordBreak: 'break-word',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default function Skills() {
      return (
      <div className="App-Skills">
        <h1>Skills</h1>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <Item><p><img alt="Project Management/Agile/Scrum" className="skillImg" src='/images/scrum.png' /></p>Project Management/Agile/Scrum</Item>
            <Item><p><img alt="PHP" className="skillImg" src='/images/php-icon.png' /></p>PHP</Item>
            <Item><p><img alt="Javascript" className="skillImg" src='/images/javascript.png' /></p>Javascript/React</Item>
            <Item><p><img alt="MYSQL" className="skillImg" src='/images/mysql.png' /></p>MYSQL/Postgre</Item>
            <Item><p><img alt="HTML5" className="skillImg" src='/images/html5.png' /></p>HTML5/CSS3</Item>
            <Item><p><img alt="AWS" className="skillImg" src='/images/aws.png' /></p>AWS/Linux/Apache/Nginx</Item>
          </Box>

          <h1>LeetCode</h1>
          <div className="leetCodeStatBox" >
            <img className="leetCodeStat" alt="test" src="https://leetcode-stats-six.vercel.app/api?username=cyruslee9266&theme=dark" />
          </div>

            <h1>Tools</h1>
          <div className="ToolsBox">
            <p>Project Management Software: Jira/confluence/Bitbucket</p>
            <p>Development Tools: Sublime Text/Mysql workbench/Xcode/visual studio code</p>
            <p>Operating System: Linux</p>
          </div>
      </div>
      );
}