import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  lineHeight: '60px',
}));

const darkTheme = createTheme({ palette: { mode: 'dark' } });

const projectImgArray = [
    ["1","viu.com","#Project Management #Partnership #Demarcation #Payment & Subscription","/images/subscription.png"],
    ["2","news.now.com","#Web Development #JSP","/images/news.png"],
    ["3","finance.now.com","#Web and Mobile Apps Development #PHP #Objective C","/images/finance.png"],
    ["4","CMS Migration to AWS","#Cloud Formation #Java","/images/awslogo-image.png"],
    ["5","Fiso web development","#Web Development #PHP #Masonry #Jquery","/images/fiso.png"],
    ["6","HKJC Wechat Game","#Wechat API","/images/hkjc_wechat_2.png"],
    ["7","K11 Supernova Event","#3D projector #FFmpeg #PHP","/images/k11_1.png"],
    ["8","Studio City Macau","#Video Booth #FFmpeg #Flash #goLang","/images/studio_city_2.jpeg"]
  ];

export default function Project() {
      return (
      <div className="App-project">
        <h1>Projects</h1>
          <Grid container spacing={2}>
            {[darkTheme].map((theme, index) => (
              <Grid item xs={12} key={index}>
                <ThemeProvider theme={theme}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: 'background.default',
                      display: 'grid',
                      gridTemplateColumns: { md: '1fr 1fr' },
                      gap: 4,
                    }}
                  >
                    {projectImgArray.map((element) => (
                      <Item key={element[0]} elevation={12}>
                        <h2>{element[1]}</h2>
                        <p className="projectDesc">{element[2]}</p>
                        <p><img alt={element[2]} className="projectImg" src={element[3]} /></p>
                      </Item>
                    ))}
                  </Box>
                </ThemeProvider>
              </Grid>
            ))}
          </Grid>
      </div>
      );
}