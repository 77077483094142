import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; 

import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';    

export default function Education() {
      return (
      <div className="App-edu">
        <h1>Education</h1>
        <VerticalTimeline>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(50 89 56)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Sep 2014 – Sep 2018"
            iconStyle={{ background: 'rgb(243, 33, 81)', color: '#fff' }}
            icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">Polytechnic University</h3>
            <h6 className="vertical-timeline-element-subtitle">Bachelor of Arts in Computing </h6>
            <p>
              Major: Computing , GPA 3.22
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(50 89 56)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="Sep 2011 – Sep 2013"
            iconStyle={{ background: 'rgb(243, 33, 81)', color: '#fff' }}
            icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">HKU SPACE Community College</h3>
            <h6 className="vertical-timeline-element-subtitle">Associate Degree of Information Technology </h6>
            <p>
              Major: Computer Science , GPA 3.15
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
              iconStyle={{ background: 'rgb(50 89 56)', color: '#fff' }}
              icon={<StarIcon />}
            />
        </VerticalTimeline>
        {/*<div className="gallery">
          <PhotoAlbum onClick={ClickHandler} layout="masonry" photos={photos} />
        </div>*/}
      </div>
      );
}