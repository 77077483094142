import './App.css';


function Summary() {

  return (
    <p>Vancouver BC | Cyruslee9266@gmail.com </p>
  )
}

export default Summary;